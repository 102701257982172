@import "./../Common/variables.scss";

.app {

  display: block;


  .logo-container {
    height: 40vh;

    display: flex;
    align-items: flex-end; /* Align content to the bottom */
    .appLogo {
      margin: 0 auto 0 auto;
      max-width: unset;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 500px;
        height: auto;
        max-height: unset;

        &.mobile {
          display: none;
        }
        display: block;
       
      }
    }
  }

  .search-container {
    height: 22vh;
    padding: 0 1rem;

    button.search-now {
      margin: 1.5rem auto 0 auto;
      display: block;
      border: 1px solid $borderColor;
      padding: $buttonPadding;
      cursor: pointer;
      background-color: unset;
      border-radius: $borderRadius;
      transition-duration: $transitionDuration;

      &:hover {
        border: 1px solid transparent;
        background-color: $buttonHoverColor;
        color: $brandColor;
      }
    }

    .SearchBarComponent {
      margin: 0 auto 0 auto;
    
    }

    
  }

}