@import "./../../Common/variables.scss";

.SearchIndexWidget {
  width: 600px;
  padding: 1rem;
  border-radius: $borderRadius;
  transition-duration: $transitionDuration;
  width: -webkit-fill-available;

  &:hover {
    background-color: whitesmoke;
  }

  .description {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: rgb(115, 115, 115);
    text-decoration: none;

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    .linkName {
      padding: 0;
      margin: 0;
      text-decoration: none;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .title {
      padding: 0;
      margin: 0.25rem 0;
      color: blue;
      width: 100%;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
