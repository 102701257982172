.appLogo {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 175px;
  // max-height: 50px;

  @media only screen and (max-width: 767px) {
    /* Styles for small screens (mobile) */
    width: unset;
  }


  img, svg{
    width: 100%;
    //max-width: 350px;
    max-height: 55px;

    display: block;

    &.mobile {
      display: none;
    }

    @media only screen and (max-width: 767px) {
      /* Styles for small screens (mobile) */
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
}
