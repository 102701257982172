@import './../Common/variables.scss';

.SearchBarComponent {
    position: relative;
    width: 100%;
    max-width: 500px;

    .input {
        display: flex;
        align-items: center;
        padding: 0.5rem .75rem;
        input {
            border: none;
            outline: none;
            padding: .25rem .50rem;

            width: stretch;
        }

        position: relative;
        background-color: white;
        border: none;
        border-radius: $borderRadius;
      
        width: -webkit-fill-available;
        
        border: 2px solid rgb(228, 228, 228);
        z-index: 15;

        
    
        &:focus-within {
            outline: none;
            border: 2px solid rgba($brandColor, 0.5);
        }
    }
}
