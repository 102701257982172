
$brandColor: #5759e5;
$onBrandColor: #FFFFFF;

$headerAvatarSize: 47px;
$transitionDuration: 300ms;
$borderRadius: 0.5rem;
$borderColor: rgba(#cecece, 0.5);
$hoverColor: rgba(0,0,0,0.1);

$buttonPadding: 0.5rem 1.25rem;
$inputPadding: 0.75rem;
$buttonHoverColor: #f0f0f0;