.AppHeader {
  // position: relative;
  // display: flex;
  // padding: 0.5rem;
  // flex-direction: row;
  // align-items: center;
  // justify-content: right;
  //background-color: blue;
  .buttons {
    position: relative;
    display: flex;
    gap: 0.5rem;
    width: fit-content;
  }

  .SearchBarLogo {
    display: flex;
    gap: 1rem;
    margin-right: auto;
    align-items: center;

    .appLogo {
      transform: none;
      position: relative;
      top: unset;
      bottom: unset;
      width: 200px;
      height: 30px;
      overflow: hidden;
      display: flex;
      align-items: center;
      left: unset;
    }
  }
}
