@import "./../../Common/variables.scss";

.SearchNewsIndexWidget {
  width: 600px;
  padding: 1rem;
  border-radius: $borderRadius;
  transition-duration: $transitionDuration;
  width: -webkit-fill-available;


  &:hover {
    background-color: whitesmoke;
  }

  .description {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: rgb(115, 115, 115);
    text-decoration: none;

    display: flex;
    flex-direction: row;
    position: relative;

    > div {
      position: relative;
      width: auto;
    }

    > img {
      position: relative;
      width: 25%;
      height: fit-content;
      border-radius: $borderRadius;
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    .linkName {
      padding: 0;
      margin: 0;
      text-decoration: none;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .title {
      padding: 0;
      margin: 0.25rem 0;
      color: blue;
      width: 100%;

      text-overflow: ellipsis;
      white-space: wrap;
      overflow: hidden;
    }
  }
}
