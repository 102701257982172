@import './../../Common/variables.scss';

.SearchQueryPreviewComponent {
    position: absolute;
    top: 0;
    padding-top: 50px;
    z-index: 10;

    background-color: white;
    border: none;
    border-radius: $borderRadius;
    //padding: 0.75rem 1rem;
    width: stretch; /* Microsoft Edge */
    
    display: none;
    //max-height: 250px;
    overflow: hidden;
    border: 1px solid $borderColor;

    &[data-show=true] {
        display: block;
    }

    > a {
        display: block;
        padding: 0.5rem;
        text-decoration: none;
        color: black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        svg {
            color: inherit;
        }

        span {
            margin-left: .5rem;
            margin-right: auto;
            color: inherit;
        }

        button {
            background-color: transparent;
            border: none;
            display: none;
            cursor: pointer;
        }
        
        &:hover {
            button {
                display: block;
            }
        }

        &:hover {
            background-color: $hoverColor;
        }
    }
}