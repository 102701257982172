@import "./../Common/variables.scss";

$mainResultWidth: 650px;

body {
  @media only screen and (max-width: 767px) {
    /* Styles for small screens (mobile) */

    overflow-x: hidden;
  }
}

.CommonHeader {
  .appLogo {
    width: 10vw;
  }
}

.SearchPage {
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  max-width: 100%;

  .searchTypes {
    overflow: hidden;
    ul {
      margin: auto;
      max-width: 75%;
      width: 100%;
      padding: 0 .5rem;
      list-style: none;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      
      li {
        padding: 0.5rem 0.75rem;
        width: fit-content;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        border-radius: $borderRadius;
        transition-duration: $transitionDuration;

        &:hover,
        &[is-active="1"] {
          color: $brandColor;
          background-color: $buttonHoverColor;
        }
      }
    }

    @media screen and (max-width: 768px) {
      ul {
        flex-direction: row;
        gap: 0.5rem;
        max-width: 100%;

        overflow-y: auto; /* Add scroll for overflow on the y-axis */
        padding: 0 .5rem;
        

        li {
          width: 100%;
        }
      }
    }
  }

  .timer {
    margin: auto;
    max-width: 75%;
    width: 100%;

    opacity: 0.5;
    padding: 0.5rem 1rem;

    @media only screen and (max-width: 767px) {
      /* Styles for small screens (mobile) */
      margin-left: unset;
    }
  }

  .no-results-message {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
    color: #888; /* Adjust the color as needed */
  }
  

  .images-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    width: auto;
    padding: 0.5rem;
   

    > a {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.25rem;
      border-radius: $borderRadius;
      text-decoration: none;
      max-width: 100%; /* Set a maximum width for the <a> element */
      overflow: hidden;
      white-space: nowrap; /* Restrict the content to one line */

      &:hover {
        background-color: $borderColor;
        text-decoration: underline;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: $borderRadius;
      }

      h3 {
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 0;
        margin: 0;
      }

      p {
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 0;
        margin: 0;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  .videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    width: auto;
    padding: 0.5rem;
  
    .video-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: $borderRadius;
  
      iframe {
        width: 100%;
        height: 200px; /* Set a fixed height, adjust as needed */
        object-fit: cover; /* Ensure the video covers the container without stretching */
      }
  
      h3, p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      h3 {
        font-size: 0.875rem;
        margin-top: 0.5rem;
      }
  
      p {
        font-size: 0.75rem;
      }
    }
  }
  
  

  .SearchResults {
    margin: auto auto 0 10%;
    max-width: 80%;
    width: 100%;
    display: grid;

    grid-template-columns: $mainResultWidth calc($mainResultWidth / 2);
    padding: 0 1rem;

    @media only screen and (max-width: 767px) {
      /* Styles for small screens (mobile) */
      grid-template-columns: 100%;
      max-width: 100%;
      padding: 0;
    }

    .Results {
      display: flex;
      flex-direction: column;
      width: auto;
      max-width: $mainResultWidth;
      gap: 1rem;
      padding: 1rem;

      @media only screen and (max-width: 767px) {
        /* Styles for small screens (mobile) */

        padding: 0.5rem;
      }

      &.separated {
        //background-color: rgb(250, 250, 250);
        border-left: 1px solid rgb(240, 240, 240);
        //border-radius:  $borderRadius;
        height: fit-content;
        max-width: calc( $mainResultWidth / 1.33);

        @media only screen and (max-width: 767px) {
          /* Styles for small screens (mobile) */
          border-left: unset;
          border-top: 1px solid rgb(240, 240, 240);
        }
      }
    }
  }

  .SearchPagination {
    display: flex;
    padding: 2rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      /* Styles for small screens (mobile) */
      padding: 1rem;
    }
    > button {
      padding: 0.5rem;
      cursor: pointer;
      border: none;
      border-radius: 15px;
      background-color: white;
      &[is-current="1"] {
        background-color: rgb(232, 232, 232);
      }

      &[display="0"] {
        display: none;
      }
    }
  }
}
